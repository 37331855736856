.read_more {
  @apply text-blue-500;
}

.review_block {
  @apply p-4;
}

.review_block__header {
  @apply mb-2 flex flex-col space-y-3 md:flex-row md:space-y-0 md:space-x-3;
}

.review_block__header__top {
  @apply flex flex-col items-center justify-center space-y-1 align-middle;
}

.review_block__header__bottom > h4 {
  @apply text-lg font-bold;
}

.review_block__header__bottom > p {
  @apply text-sm;
}

.review_block__content {
  @apply space-y-2;
}

.review_section {
  @apply px-4 py-8;
}

.review_section__header {
  @apply mb-8;
}

.review_section__header > h3 {
  @apply text-center text-3xl font-bold uppercase sm:text-4xl;
}

.review_section__content {
  @apply container mx-auto grid grid-cols-2 sm:grid-cols-3 xl:grid-cols-4;
}
