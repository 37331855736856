.UserContent {
  @apply py-12;
}

.UserContent > h3 {
  @apply font-display mb-4 px-4 text-center text-3xl font-bold;
}

.UserContent__Wrapper {
  @apply grid grid-cols-2 sm:grid-cols-4;
}
