.Section {
  @apply p-4 md:mb-8;
}

.Container {
  @apply container mx-auto grid grid-cols-1 items-center sm:gap-8 sm:grid-cols-2;
}

.Section_image {
  @apply z-0;
}

.Title {
  @apply font-display mb-4 text-3xl font-black md:text-4xl 2xl:text-5xl;
}

.Title_Mobile {
  @apply block lg:hidden;
  composes: Title;
}

.Title_Desktop {
  @apply hidden lg:block;
  composes: Title;
}

.Section__Content ul {
  @apply my-4 list-inside list-disc text-lg;
}

.Section__Content ul > li {
  @apply mb-2;
}

.Section__Content ul > li > strong {
  @apply font-bold;
}

.Section__Content p {
  @apply mb-4 text-lg;
}

.Section__Content p strong {
  @apply font-bold;
}
