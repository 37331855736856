/* vietnamese */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/17246dc668480671-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/f4ee854b2722cbb1-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(/_next/static/media/51b9f9f30f86448c-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* vietnamese */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/81624f86d59dc232-s.woff2) format('woff2');
  unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+0300-0301, U+0303-0304, U+0308-0309, U+0323, U+0329, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/23e3b81d4c10e57c-s.woff2) format('woff2');
  unicode-range: U+0100-02BA, U+02BD-02C5, U+02C7-02CC, U+02CE-02D7, U+02DD-02FF, U+0304, U+0308, U+0329, U+1D00-1DBF, U+1E00-1E9F, U+1EF2-1EFF, U+2020, U+20A0-20AB, U+20AD-20C0, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
  font-family: 'Barlow Condensed';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(/_next/static/media/65385b422c1e9499-s.p.woff2) format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+0304, U+0308, U+0329, U+2000-206F, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}@font-face {font-family: 'Barlow Condensed Fallback';src: local("Arial");ascent-override: 130.73%;descent-override: 26.15%;line-gap-override: 0.00%;size-adjust: 76.49%
}.__className_f5ce34 {font-family: 'Barlow Condensed', 'Barlow Condensed Fallback';font-style: normal
}

.styles_read_more__qt_bL {

    --tw-text-opacity: 1;

    color: rgb(59 130 246 / var(--tw-text-opacity, 1))
}

.styles_review_block__SjiGK {

    padding: 1rem
}

.styles_review_block__header__PoDGv {

    margin-bottom: 0.5rem;

    display: flex;

    flex-direction: column
}

.styles_review_block__header__PoDGv > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(0.75rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(0.75rem * var(--tw-space-y-reverse))
}

@media (min-width: 768px) {

    .styles_review_block__header__PoDGv {

        flex-direction: row
    }

    .styles_review_block__header__PoDGv > :not([hidden]) ~ :not([hidden]) {

        --tw-space-y-reverse: 0;

        margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));

        margin-bottom: calc(0px * var(--tw-space-y-reverse));

        --tw-space-x-reverse: 0;

        margin-right: calc(0.75rem * var(--tw-space-x-reverse));

        margin-left: calc(0.75rem * calc(1 - var(--tw-space-x-reverse)))
    }
}

.styles_review_block__header__top__D6EJh {

    display: flex;

    flex-direction: column;

    align-items: center;

    justify-content: center
}

.styles_review_block__header__top__D6EJh > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(0.25rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(0.25rem * var(--tw-space-y-reverse))
}

.styles_review_block__header__top__D6EJh {

    vertical-align: middle
}

.styles_review_block__header__bottom__l_Gdv > h4 {

    font-size: 1.125rem;

    line-height: 1.75rem;

    font-weight: 700
}

.styles_review_block__header__bottom__l_Gdv > p {

    font-size: 0.875rem;

    line-height: 1.25rem
}

.styles_review_block__content__7wpdL > :not([hidden]) ~ :not([hidden]) {

    --tw-space-y-reverse: 0;

    margin-top: calc(0.5rem * calc(1 - var(--tw-space-y-reverse)));

    margin-bottom: calc(0.5rem * var(--tw-space-y-reverse))
}

.styles_review_section__xqev3 {

    padding-left: 1rem;

    padding-right: 1rem;

    padding-top: 2rem;

    padding-bottom: 2rem
}

.styles_review_section__header__c_bCX {

    margin-bottom: 2rem
}

.styles_review_section__header__c_bCX > h3 {

    text-align: center;

    font-size: 1.875rem;

    line-height: 2.25rem;

    font-weight: 700;

    text-transform: uppercase
}

@media (min-width: 640px) {

    .styles_review_section__header__c_bCX > h3 {

        font-size: 2.25rem;

        line-height: 2.5rem
    }
}

.styles_review_section__content__MWexk {

    width: 100%
}

@media (min-width: 640px) {

    .styles_review_section__content__MWexk {

        max-width: 640px
    }
}

@media (min-width: 768px) {

    .styles_review_section__content__MWexk {

        max-width: 768px
    }
}

@media (min-width: 1024px) {

    .styles_review_section__content__MWexk {

        max-width: 1024px
    }
}

@media (min-width: 1280px) {

    .styles_review_section__content__MWexk {

        max-width: 1280px
    }
}

@media (min-width: 1536px) {

    .styles_review_section__content__MWexk {

        max-width: 1536px
    }
}

.styles_review_section__content__MWexk {

    margin-left: auto;

    margin-right: auto;

    display: grid;

    grid-template-columns: repeat(2, minmax(0, 1fr))
}

@media (min-width: 640px) {

    .styles_review_section__content__MWexk {

        grid-template-columns: repeat(3, minmax(0, 1fr))
    }
}

@media (min-width: 1280px) {

    .styles_review_section__content__MWexk {

        grid-template-columns: repeat(4, minmax(0, 1fr))
    }
}

.styles_Section__7mBoF {
  padding: 1rem;
}

@media (min-width: 768px) {

  .styles_Section__7mBoF {
    margin-bottom: 2rem;
  }
}

.styles_Container__WlSlU {
  width: 100%;
}

@media (min-width: 640px) {

  .styles_Container__WlSlU {
    max-width: 640px;
  }
}

@media (min-width: 768px) {

  .styles_Container__WlSlU {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {

  .styles_Container__WlSlU {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {

  .styles_Container__WlSlU {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {

  .styles_Container__WlSlU {
    max-width: 1536px;
  }
}

.styles_Container__WlSlU {
  margin-left: auto;
  margin-right: auto;
  display: grid;
  grid-template-columns: repeat(1, minmax(0, 1fr));
  align-items: center;
}

@media (min-width: 640px) {

  .styles_Container__WlSlU {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 2rem;
  }
}

.styles_Section_image__Qs9fq {
  z-index: 0;
}

.styles_Title__slaB1 {
  margin-bottom: 1rem;
  font-family: Roboto Flex, Roboto, sans-serif;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 900;
}

@media (min-width: 768px) {

  .styles_Title__slaB1 {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }
}

@media (min-width: 1536px) {

  .styles_Title__slaB1 {
    font-size: 3rem;
    line-height: 1;
  }
}

.styles_Title_Mobile__ubqnp {
  display: block;
}

@media (min-width: 1024px) {

  .styles_Title_Mobile__ubqnp {
    display: none;
  }
}

.styles_Title_Mobile__ubqnp {
}

.styles_Title_Desktop__rL3ED {
  display: none;
}

@media (min-width: 1024px) {

  .styles_Title_Desktop__rL3ED {
    display: block;
  }
}

.styles_Title_Desktop__rL3ED {
}

.styles_Section__Content__WRqNb ul {
  margin-top: 1rem;
  margin-bottom: 1rem;
  list-style-position: inside;
  list-style-type: disc;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.styles_Section__Content__WRqNb ul > li {
  margin-bottom: 0.5rem;
}

.styles_Section__Content__WRqNb ul > li > strong {
  font-weight: 700;
}

.styles_Section__Content__WRqNb p {
  margin-bottom: 1rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.styles_Section__Content__WRqNb p strong {
  font-weight: 700;
}

.styles_UserContent__O6np_ {

    padding-top: 3rem;

    padding-bottom: 3rem
}

.styles_UserContent__O6np_ > h3 {

    margin-bottom: 1rem;

    padding-left: 1rem;

    padding-right: 1rem;

    text-align: center;

    font-family: Roboto Flex, Roboto, sans-serif;

    font-size: 1.875rem;

    line-height: 2.25rem;

    font-weight: 700
}

.styles_UserContent__Wrapper__asdFB {

    display: grid;

    grid-template-columns: repeat(2, minmax(0, 1fr))
}

@media (min-width: 640px) {

    .styles_UserContent__Wrapper__asdFB {

        grid-template-columns: repeat(4, minmax(0, 1fr))
    }
}

